module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-preview/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dorothea","path":"/preview"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dóróthea Ármann","short_name":"Dóróthea Ármann","description":"Sjálfstætt starfandi verkefna- og viðburðastjóri með augun opin fyrir nýjum og spennandi ævintýrum.","background_color":"#F2AF29","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"975751089aa76b143b7fae429a0529bb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
